import {Product as ProductInterface} from 'components/customer/Product/entity/Product';
import {PartialRoom} from 'shared/types/PartialRoom';
import {ToeKickHeight} from 'assets';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';

export const getToeKickSelection = ({
    product,
    room,
    cabinetFormFieldDefaults,
    measurement,
}: {
    product: ProductInterface;
    room: PartialRoom;
    cabinetFormFieldDefaults: Record<string, string | number>;
    measurement: string;
}): SelectionInterface[] => {
    const rules = [];
    if (typeof product.cabinet_toekick != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_toekick !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_toekick !=
                    product.cabinet_toekick &&
                String(product.cabinet_toekick) != room.toeKickHeight) ||
            (typeof cabinetFormFieldDefaults.cabinet_toekick == 'undefined' &&
                String(product.cabinet_toekick) != room.toeKickHeight)
        ) {
            rules.push({
                key: 'toe_kick_height',
                name: 'Toe Kick Height',
                is_saved:
                    room.customerOptions && room.customerOptions.isToeKickSet,
                set_key: 'isToeKickSet',
                room_data: `${room.toeKickHeight} ${measurement}`,
                product_data: `${product.cabinet_toekick} ${measurement}`,
                value: product.cabinet_toekick,
                image: String(ToeKickHeight),
                apply_to_room: true,
            });
        }
    }

    if (
        product.cabinet_name.includes('KickLength') &&
        typeof product.cabinet_panel_length != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_toekick !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_toekick !=
                    product.cabinet_panel_length &&
                String(product.cabinet_panel_length) != room.toeKickHeight) ||
            (typeof cabinetFormFieldDefaults.cabinet_toekick == 'undefined' &&
                String(product.cabinet_panel_length) != room.toeKickHeight)
        ) {
            rules.push({
                key: 'toe_kick_height',
                name: 'Toe Kick Height',
                is_saved:
                    room.customerOptions && room.customerOptions.isToeKickSet,
                set_key: 'isToeKickSet',
                room_data: `${room.toeKickHeight} ${measurement}`,
                product_data: `${product.cabinet_panel_length} ${measurement}`,
                value: product.cabinet_panel_length,
                image: String(ToeKickHeight),
                apply_to_room: true,
            });
        }
    }

    return rules;
};
