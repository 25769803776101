import {GenericObject} from 'components/customer/Product/entity';
import {ProductForm} from 'components/customer/Product/entity/ProductForm';

export const getRequiredReferences = (
    productConfig: ProductForm,
    initialValues: GenericObject
) => {
    let drawerRunnerInfoReference = {};
    let partitionHeightReferenceStore: string;
    const shelfSectionHeight: GenericObject = {};
    const shelvesStructureStore: GenericObject = {};
    const shelfTypes: GenericObject = {};
    const shelfStyles: GenericObject = {};

    productConfig.structure.forEach((structure) => {
        let allFieldsetsHidden = true;

        structure.fieldsets.forEach((fieldset) => {
            const allFieldsHidden =
                fieldset.fields.length != 0 &&
                fieldset.fields.every((field) => {
                    if (field.name == 'sub_panel') return true;

                    if (
                        field.hasOwnProperty('options') &&
                        field.options.hasOwnProperty('visible')
                    ) {
                        if (
                            (typeof field.options.visible === 'boolean' &&
                                !field.options.visible) ||
                            field.options.visible == 0
                        ) {
                            return true;
                        }

                        return false;
                    }

                    return false;
                });

            if (allFieldsHidden) {
                if (!fieldset.hasOwnProperty('options')) {
                    fieldset.options = {};
                }

                fieldset.options.visible = false;
            } else {
                allFieldsetsHidden = false;
            }
        });

        if (allFieldsetsHidden) {
            if (!structure.hasOwnProperty('options')) {
                structure.options = {};
            }

            structure.options.visible = false;
        }

        if (structure.name === 'Shelves') {
            structure.fieldsets.forEach((fieldset) => {
                if (
                    [
                        'shelves',
                        'upper_shelves',
                        'middle_shelves',
                        'lower_shelves',
                    ].includes(fieldset.name)
                ) {
                    shelvesStructureStore[fieldset.name] = fieldset.fields.map(
                        (field) => {
                            if (
                                field.name.indexOf('shelf_type') >= 0 &&
                                field.hasOwnProperty('options') &&
                                field.options.hasOwnProperty('selectOptions') &&
                                field.options.selectOptions.length
                            ) {
                                shelfTypes[fieldset.name] =
                                    field.options.selectOptions[0].value;
                            }

                            if (
                                field.name.indexOf('shelf_style') >= 0 &&
                                field.hasOwnProperty('options') &&
                                field.options.hasOwnProperty('selectOptions') &&
                                field.options.selectOptions.length
                            ) {
                                shelfStyles[fieldset.name] =
                                    field.options.selectOptions[0].value;
                            }

                            return field.name;
                        }
                    );

                    if (
                        fieldset.hasOwnProperty('options') &&
                        fieldset.options.hasOwnProperty('shelfSectionHeight')
                    ) {
                        shelfSectionHeight[fieldset.name] =
                            fieldset.options.shelfSectionHeight;
                    }
                }

                if (fieldset.name === 'partition_heights') {
                    if (
                        fieldset.hasOwnProperty('options') &&
                        fieldset.options.hasOwnProperty('totalHeight')
                    ) {
                        partitionHeightReferenceStore =
                            fieldset.options.totalHeight;
                    } else {
                        if (initialValues.hasOwnProperty('cabinet_height'))
                            partitionHeightReferenceStore = 'cabinet_height';
                    }
                }
            });
        }

        if (structure.name === 'Drawers') {
            let shelvesIndex;

            structure.fieldsets.forEach((fieldset, index) => {
                if (
                    fieldset.hasOwnProperty('quantity') &&
                    fieldset.hasOwnProperty('fields') &&
                    fieldset.fields.length
                ) {
                    shelvesIndex = index;
                }
            });

            if (shelvesIndex) {
                drawerRunnerInfoReference = {
                    ...structure.fieldsets[Number(shelvesIndex)].fields[0]
                        .options,
                    ...structure.fieldsets[Number(shelvesIndex)].options,
                };
            } else drawerRunnerInfoReference = undefined;
        }
    });

    return {
        drawerRunnerInfoReference,
        shelfSectionHeight,
        partitionHeightReferenceStore,
        shelvesStructureStore,
        shelfTypes,
        shelfStyles,
    };
};
