import React, {useEffect, useMemo} from 'react';
import {Col, Form} from 'react-bootstrap';
import {
    DrawerRunnerRequest,
    useLazyGetRunnerQuery,
} from 'components/customer/Product/store/drawerRunnerApi';
import {
    DrawerKey,
    DrawerFields,
} from 'components/customer/Product/entity/Drawer';
import {useFormikContext} from 'formik';
import {useRunnerRequestParams} from 'components/customer/Product/Drawer/helpers/useRunnerRequestParams';
import {useNotificationContext, useProductContext} from 'contexts';
import {validationErrorAppliesToField} from 'shared/validator';
import {isEmpty} from 'lodash';
import SelectField from 'shared/components/SelectField';
import {WithTooltipWrapper} from 'components/manufacturer/shared/InputFieldWithTooltip';
import {FormGroup} from 'shared/components/Forms/FormGroup';

interface DrawerRunnerProps {
    isDrawerComponent?: boolean;
    runnerIndex?: number;
    fieldName: string;
    drawerTypeFieldName: string;
    drawerFaceHeightFieldName: string;
    fingerPullFieldName: string;
    drawerDepth: string;
    labelColSpan?: number;
}

interface RunnerSpecs {
    height: number;
    depth: number;
}

export const DrawerRunner = ({
    isDrawerComponent = false,
    runnerIndex,
    fieldName,
    drawerTypeFieldName,
    drawerFaceHeightFieldName,
    fingerPullFieldName,
    drawerDepth,
    labelColSpan = 5,
}: DrawerRunnerProps) => {
    const {values, setFieldValue} = useFormikContext<DrawerFields>();
    const {params} = useRunnerRequestParams(
        runnerIndex,
        isDrawerComponent,
        drawerTypeFieldName,
        drawerDepth,
        drawerFaceHeightFieldName,
        fingerPullFieldName
    );
    const {messages} = useNotificationContext();
    const {
        addValidationData,
        getValidationData,
    }: {
        addValidationData: (a: string, b: RunnerSpecs) => void;
        getValidationData: (a: string) => RunnerSpecs;
    } = useProductContext();

    const [getRunners, {data, isFetching, isLoading}] = useLazyGetRunnerQuery();

    const value = useMemo(() => {
        if (isDrawerComponent) {
            return values[fieldName];
        }

        const drawer = values.drawers[runnerIndex];
        const drawerkey = fieldName as DrawerKey;

        return drawer[drawerkey];
    }, [values, isDrawerComponent]);

    const triggerGetRunners = async (runnerProps: DrawerRunnerRequest) => {
        if (runnerProps.insertType == null || runnerProps.faceHeight <= -1) {
            return;
        }

        const {data} = await getRunners(runnerProps, true);
        if (data?.length == 0) {
            void setFieldValue(`drawers[${runnerIndex}.${fieldName}]`, -1);
        }
    };

    const errorMessage = useMemo(() => {
        const errors = messages?.errors;
        if (errors && Array.isArray(errors)) {
            return errors.find((error) =>
                validationErrorAppliesToField(
                    error,
                    'drawer_runner_specs',
                    runnerIndex
                )
            )?.message;
        }

        return null;
    }, [messages]);

    useEffect(() => {
        if (params) {
            void triggerGetRunners(params);
        }
    }, [params]);

    useEffect(() => {
        if (isDrawerComponent && data && value) {
            const runner = data.find((option) => option.id == value);
            const validationData = getValidationData('drawer_runner_specs');
            if (runner && isEmpty(validationData)) {
                // set validation data once for the on load of update product
                addValidationData('drawer_runner_specs', {
                    height: runner.runnerHeight,
                    depth: runner.runnerDepth,
                });
            }
        }
    }, [value, data]);

    // effect to set runner default value
    useEffect(() => {
        const runner = data?.find((option) => option.id == value);
        // only set the default if no runner yet or the current runner is not present in the options
        if (data?.length && !runner) {
            if (isDrawerComponent) {
                const defaultRunner = data[0];
                void setFieldValue(fieldName, defaultRunner.id);
                if (defaultRunner) {
                    addValidationData('drawer_runner_specs', {
                        height: defaultRunner.runnerHeight,
                        depth: defaultRunner.runnerDepth,
                    });
                }
            } else {
                void setFieldValue(
                    `drawers[${runnerIndex}.${fieldName}]`,
                    data[0].id
                );
            }
        }
    }, [data, value]);

    return (
        <FormGroup>
            <Form.Label column md={labelColSpan} xs={12} style={{zIndex: 1}}>
                Runner
                <span>*</span>
            </Form.Label>
            <Col md={12 - labelColSpan} xs={12}>
                <WithTooltipWrapper
                    show={!!errorMessage}
                    tooltip={errorMessage}>
                    <SelectField
                        name={
                            isDrawerComponent
                                ? fieldName
                                : `drawers[${runnerIndex}.${fieldName}]`
                        }
                        placeholder="No runners small enough to fit in cabinet depth"
                        options={data?.map(({id, name}) => ({
                            label: name,
                            value: String(id),
                        }))}
                        isLoading={isFetching || isLoading}
                        invalid={!!errorMessage}
                        isDisabled={!!errorMessage}
                    />
                </WithTooltipWrapper>
            </Col>
        </FormGroup>
    );
};
