import React, {useState, useEffect, useCallback} from 'react';
import CollapsibleHeader, {CancelButton} from './CollapsibleHeader';
import {
    DrawerRunner,
    GenericFields,
    MaterialDefaults,
    Sizes,
    DoorSubPanels,
    RailPositions,
    PartitionHeights,
    DrawerFaces,
    DoorDrillings,
    Shelves,
    VerticalPartition,
} from 'components/customer/Product';
import {ProductionOptions} from 'components/customer/Product/Specs/ProductionOptions';
import {Field} from 'components/customer/Product/entity/Field';
import {Fieldset} from 'components/customer/Product/entity/Fieldset';
import {PreviewFormValues} from 'Preview3D/types';
import Excel from 'shared/Excel';
import {MidRails} from './Specs/MidRails';
import {DrawerEdges} from './Specs/DrawerEdges';
import {Drillings} from './Specs/Drillings';

export const FIELDSET_LAYOUT = {
    COMPOUND: 'compound',
    SHELVES: 'shelves',
    DRILLINGS: 'panel_shelf_drillings',
    DOOR_DRILLINGS: 'door_drillings',
    MID_RAILS: 'mid_rails',
    DRAWER_EDGES: 'drawer_panel_edges',
};

export const FIELDSETS = {
    GENERIC: 'generic',
    SIZES: 'sizes',
    COMMENTS: 'comments',
    ADDITIONAL: 'additional',
    MATERIALS: 'materials',
    DOOR_FIELDS: 'door_fields',
    MARGIN_FIELDS: 'margin_fields',
    BORDER_WIDTHS: 'border_widths',
    DOOR_EDGES: 'door_edges',
    DRAWER_EDGES: 'drawer_panel_edges',
    DRAWER_MARGINS: 'drawer_margins',
    DRAWER_RUNNERS: 'drawer_runners',
    MID_RAILS: 'mid_rails',
    HORI_MID_RAILS: 'hori_mid_rail_positions',
    VERT_MID_RAILS: 'vert_mid_rail_positions',
    SUB_PANELS: 'advanced_door_glass',
    SHELVES: 'shelves',
    UPPER_SHEPVES: 'upper_shelves',
    MIDDLE_SHELVES: 'middle_shelves',
    LOWER_SHELVES: 'lower_shelves',
    VERTICAL_SHELVES_POSITION: 'vertical_partition',
    DRILLINGS: 'panel_shelf_drillings',
    EDGINGS: 'cabinet_edges',
    PARTITION_HEIGHTS: 'partition_heights',
    DRAWER_FACES: 'drawer_face_heights',
    DOOR_DRILLINGS: 'door_drillings',
    APPLIANCE: 'appliance',
    CABINET_INCLUDE_HARDWARE: 'cabinet_include_hardware',
    SIMPLE_SHELVES: 'simple_shelves',
    DRAWER_FIELDS: 'drawer_fields',
    MATERIAL_OPTIONS: 'material_options',
    VARIATIONS: 'variations',
    SHELF_ADVANCED_CHECKBOX: 'shelf_advanced_checkbox',
    FINGERPULL: 'fingerpull',
    ADDITIONAL_SIZES: 'additional_sizes',
    RANGEHOOD: 'rangehood',
    OVEN: 'oven',
    MICROWAVE: 'microwave',
};

const FieldsetStrategy: {
    [key: string]: {
        render: (
            fields: Field[],
            fieldSet: Fieldset,
            name: string,
            layout: string,
            title: string,
            hasPreview: boolean,
            cancelButton?: React.JSX.Element
        ) => React.JSX.Element;
    };
} = {
    [FIELDSETS.SIZES]: {
        render(fields, fieldset, name, layout, title, hasPreview) {
            return <Sizes fields={fields} hasPreview={hasPreview} />;
        },
    },
    [FIELDSETS.MATERIALS]: {
        render(fields, fieldset, name, layout, title, hasPreview) {
            return <MaterialDefaults fields={fields} hasPreview={hasPreview} />;
        },
    },
    [FIELDSETS.GENERIC]: {
        render(
            fields,
            fieldset,
            fieldsetName,
            fieldsetLayout,
            fieldsetTitle,
            preview,
            cancelButton
        ) {
            return (
                <GenericFields
                    fields={fields}
                    fieldsetName={fieldsetName}
                    fieldsetLayout={fieldsetLayout}
                    fieldsetTitle={fieldsetTitle}
                    preview={preview}
                    cancelButton={cancelButton}
                />
            );
        },
    },
    [FIELDSETS.DRAWER_RUNNERS]: {
        render(fields, fieldset) {
            return <DrawerRunner field={fields[0]} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.SUB_PANELS]: {
        render(fields, fieldset) {
            return <DoorSubPanels fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.HORI_MID_RAILS]: {
        render(fields, fieldset) {
            return <RailPositions fieldset={fieldset} fieldName="rail_hori" />;
        },
    },
    [FIELDSETS.VERT_MID_RAILS]: {
        render(fields, fieldset) {
            return <RailPositions fieldset={fieldset} fieldName="rail_vert" />;
        },
    },
    [FIELDSETS.VERTICAL_SHELVES_POSITION]: {
        render(
            fields,
            fieldset,
            fieldsetName,
            fieldsetLayout,
            fieldsetTitle,
            preview
        ) {
            return (
                <VerticalPartition
                    fields={fields}
                    fieldsetName={fieldsetName}
                    fieldsetLayout={fieldsetLayout}
                    fieldsetTitle={fieldsetTitle}
                    preview={preview}
                />
            );
        },
    },
    [FIELDSETS.SHELVES]: {
        render(fields, fieldset) {
            return <Shelves fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.UPPER_SHEPVES]: {
        render(fields, fieldset) {
            return <Shelves fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.MIDDLE_SHELVES]: {
        render(fields, fieldset) {
            return <Shelves fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.LOWER_SHELVES]: {
        render(fields, fieldset) {
            return <Shelves fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.DRILLINGS]: {
        render(fields, fieldset) {
            return <Drillings fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.PARTITION_HEIGHTS]: {
        render(fields, fieldset) {
            return <PartitionHeights fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.DRAWER_EDGES]: {
        render(fields, fieldset) {
            return <DrawerEdges fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.DRAWER_FACES]: {
        render(fields, fieldset) {
            return <DrawerFaces fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.DOOR_DRILLINGS]: {
        render(fields, fieldset) {
            return <DoorDrillings fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.MID_RAILS]: {
        render(fields, fieldset) {
            return <MidRails fieldset={fieldset} />;
        },
    },
    [FIELDSETS.ADDITIONAL]: {
        render(fields, fieldset, fieldsetName) {
            return (
                <ProductionOptions
                    fields={fields}
                    fieldsetName={fieldsetName}
                />
            );
        },
    },
};

interface FieldSetProps {
    fieldset: Fieldset;
    hasPreview: boolean;
    index: number;
    values: PreviewFormValues;
    showFieldsetTitle?: boolean;
    setFieldValue: (name: string, value: string | number | boolean) => void;
}

export const FieldSet = ({
    fieldset,
    hasPreview,
    index,
    values,
    showFieldsetTitle = true,
    setFieldValue,
}: FieldSetProps) => {
    let collapsible = false;
    let defaultDisplay = true;

    if (
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('collapsible') &&
        fieldset.options.collapsible
    ) {
        collapsible = true;
        defaultDisplay = false; // Collapsed by default

        if (fieldset.options.hasOwnProperty('collapsed')) {
            if (typeof fieldset.options.collapsed == 'string') {
                try {
                    defaultDisplay = !Excel.calculate(
                        fieldset.options.collapsed,
                        values
                    );
                } catch (e) {
                    return false;
                }
            } else {
                defaultDisplay = !fieldset.options.collapsed;
            }
        }
    }

    const [displayFieldset, setDisplayFieldset] = useState(defaultDisplay);

    const clickHandler = useCallback(() => {
        if (collapsible) {
            setDisplayFieldset(!displayFieldset);
        }
    }, [collapsible, displayFieldset, setDisplayFieldset]);

    let fieldsetType = FieldsetStrategy[fieldset.name];
    const fieldsetLayout =
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('layout') &&
        fieldset.options.layout;
    let hideHorizontalRule = false;

    if (typeof fieldsetType === 'undefined') {
        fieldsetType = FieldsetStrategy[FIELDSETS.GENERIC];
    }

    if (Object.values(FIELDSET_LAYOUT).includes(fieldsetLayout)) {
        showFieldsetTitle = false;
    }

    // exceptional conditions
    if (
        fieldset.name === 'hori_mid_rail_positions' ||
        fieldset.name === 'vert_mid_rail_positions' ||
        fieldset.name === FIELDSETS.UPPER_SHEPVES ||
        fieldset.name === FIELDSETS.MIDDLE_SHELVES ||
        fieldset.name === FIELDSETS.LOWER_SHELVES
    ) {
        hideHorizontalRule = true;
    }

    if (
        fieldset.name === FIELDSETS.DRAWER_RUNNERS &&
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('layout') &&
        fieldset.options.layout === 'drawer'
    ) {
        showFieldsetTitle = false;
    }

    let fieldsetContent = null;
    let cancelButton = null;
    const hasAddField = fieldset.options?.layout == 'add' && collapsible;

    const cancelButtonHandler = useCallback(() => {
        setDisplayFieldset(!displayFieldset);
    }, [setDisplayFieldset, displayFieldset]);

    if (hasAddField)
        cancelButton = <CancelButton toggleIsOpen={cancelButtonHandler} />;

    if (displayFieldset) {
        fieldsetContent = fieldsetType.render(
            fieldset.fields,
            fieldset,
            fieldset.name,
            fieldsetLayout,
            fieldset.hasOwnProperty('title') && fieldset.title,
            hasPreview,
            cancelButton
        );
    }

    useEffect(() => {
        if (!displayFieldset && hasAddField) {
            fieldset?.fields?.forEach((field) => {
                setFieldValue(field.name, field.value);
            });
        }
    }, [displayFieldset]);

    return (
        <section
            id={`${fieldset.name}-id`}
            className={displayFieldset ? 'expanded' : 'collapsed'}>
            {!showFieldsetTitle && index !== 0 ? (
                !hideHorizontalRule ? (
                    <hr />
                ) : null
            ) : null}

            {showFieldsetTitle &&
            fieldset.hasOwnProperty('title') &&
            fieldset.title ? (
                hasAddField ? (
                    <CollapsibleHeader
                        title={fieldset.title}
                        collapsible={collapsible}
                        onClick={clickHandler}
                        isOpen={displayFieldset}
                    />
                ) : (
                    <h3
                        className="notes-and-variations"
                        style={{
                            userSelect: 'none',
                        }}
                        onClick={clickHandler}>
                        <span>{fieldset.title} </span>
                    </h3>
                )
            ) : null}
            {fieldsetContent}
        </section>
    );
};
