import {Product as ProductInterface} from 'components/customer/Product/entity/Product';
import {PartialRoom} from 'shared/types/PartialRoom';
import {TallHeight, TallDepth} from 'assets';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';
import {ProductType} from './entity/ProductType';

export const getTallProductSelection = ({
    product,
    room,
    cabinetFormFieldDefaults,
    measurement,
}: {
    product: ProductInterface;
    room: PartialRoom;
    cabinetFormFieldDefaults: Record<string, string | number>;
    measurement: string;
}): SelectionInterface[] => {
    const rules = [];
    if (product.cabinet_style != ProductType.TALL) {
        return null;
    }
    if (typeof product.cabinet_height != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_height != 'undefined' &&
                cabinetFormFieldDefaults.cabinet_height !=
                    product.cabinet_height &&
                product.cabinet_height != room.tallHeight) ||
            (typeof cabinetFormFieldDefaults.cabinet_height == 'undefined' &&
                product.cabinet_height != room.tallHeight)
        ) {
            rules.push({
                key: 'tall_height',
                name: 'Tall Height',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isTallHeightSet,
                set_key: 'isTallHeightSet',
                room_data: `${room.tallHeight} ${measurement}`,
                product_data: `${product.cabinet_height} ${measurement}`,
                value: product.cabinet_height,
                image: String(TallHeight),
                apply_to_room: true,
            });
        }
    }

    if (typeof product.cabinet_depth != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_depth != 'undefined' &&
                cabinetFormFieldDefaults.cabinet_depth !=
                    product.cabinet_depth &&
                product.cabinet_depth != parseInt(room.tallDepth)) ||
            (typeof cabinetFormFieldDefaults.cabinet_depth == 'undefined' &&
                product.cabinet_depth != parseInt(room.tallDepth))
        ) {
            rules.push({
                key: 'tall_depth',
                name: 'Tall Depth',
                is_saved:
                    room.customerOptions && room.customerOptions.isTallDepthSet,
                set_key: 'isTallDepthSet',
                room_data: `${room.tallDepth} ${measurement}`,
                product_data: `${product.cabinet_depth} ${measurement}`,
                value: product.cabinet_depth,
                image: String(TallDepth),
                apply_to_room: true,
            });
        }
    }

    return rules;
};
