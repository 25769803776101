import React, {useMemo} from 'react';
import {getCabinetFields, getHingeDirection} from 'shared/helpers';
import {
    isDeviceMediumToLarge,
    isDeviceSmall,
    useTabletSize,
} from 'shared/helpers/DeviceSize';
import {ProductContextType} from 'components/customer/Product/Specs';
import {useAppContext, useProductContext} from 'contexts';
import {Structure} from 'components/customer/Product/entity/Structure';
import {Fieldset} from 'components/customer/Product/entity/Fieldset';
import {Col} from 'react-bootstrap';
import {Sticky} from 'shared/components/Sticky';
import {DoorPreview} from 'components/customer/Product/Preview/DoorPreview';
import {SizePreview} from 'components/customer/Product/Preview/SizePreview';
import {DrillingPreview} from 'components/customer/Product/Preview/DrillingPreview';
import {DrawerPreview} from 'components/customer/Product/Preview/DrawerPreview';
import {ShelvesPreview} from 'components/customer/Product/Preview/ShelvesPreview';

export const useSpecPreview = (
    title: string,
    preview: string,
    structure: Structure,
    fieldsets: Fieldset[],
    isQFP = false
) => {
    const {userProfile} = useAppContext();
    const {
        productDataStore,
        getMaterialOptions,
        formStructure,
        currentTab,
        isMiniBrowser,
    } = useProductContext<ProductContextType>();

    const isSmallDevice = isDeviceSmall();
    const mediumToLargeDevice = isDeviceMediumToLarge();
    const isTabletSize = useTabletSize();
    const allow3DView = userProfile?.allow3DView;

    const has3DPreview =
        productDataStore.current?.template_3d &&
        productDataStore.current?.template_3d?.length > 0 &&
        allow3DView;

    const [panelPreview, hasPreview] = useMemo(() => {
        let previewContent = null;
        let hasPreview = false;
        const cabinetInfo = getCabinetFields(formStructure);
        const materialOptions = getMaterialOptions();

        switch (preview) {
            case 'edging':
                let cabinetCode;
                let doorHang;

                if (
                    productDataStore.current.hasOwnProperty('cabinet') &&
                    productDataStore.current.cabinet.hasOwnProperty(
                        'attributes'
                    )
                ) {
                    if (
                        productDataStore.current.cabinet.attributes.hasOwnProperty(
                            'code'
                        )
                    ) {
                        cabinetCode =
                            productDataStore.current.cabinet.attributes.code;
                    }
                    if (
                        productDataStore.current.cabinet.attributes.hasOwnProperty(
                            'door_hang'
                        )
                    ) {
                        doorHang =
                            productDataStore.current.cabinet.attributes.door_hang.toLowerCase();
                    }
                }

                const isProductDoor =
                    !isQFP &&
                    (cabinetInfo.hasExteriorDoor || cabinetCode == 'Door');

                if (isProductDoor) {
                    if (
                        !doorHang.includes('bifold') &&
                        !doorHang.includes('rollup')
                    ) {
                        const hingeDirection = getHingeDirection(structure);

                        previewContent = (
                            <Col
                                sm={isMiniBrowser || isTabletSize ? 12 : 6}
                                id="canvas-wrapper">
                                <Sticky>
                                    <DoorPreview
                                        hingeDirection={hingeDirection}
                                        isQFP={isQFP}
                                        has3DPreview={has3DPreview}
                                    />
                                </Sticky>
                            </Col>
                        );
                        hasPreview = true;
                    } else {
                        previewContent = <Col sm={5} id="canvas-wrapper" />;
                    }
                }
                break;

            case 'standard':
                if (
                    structure.hasOwnProperty('canvasPreview') &&
                    structure.canvasPreview
                ) {
                    previewContent = (
                        <Col
                            sm={mediumToLargeDevice ? 5 : isTabletSize ? 12 : 6}
                            style={{paddingLeft: isTabletSize ? 15 : 25}}>
                            <SizePreview isMiniBrowser={isMiniBrowser} />
                        </Col>
                    );
                    hasPreview = true;
                }
                break;

            case 'panel_shelf_drillings':
                previewContent = (
                    <Col sm={isTabletSize ? 12 : 4} id="canvas-wrapper">
                        <DrillingPreview />
                    </Col>
                );
                hasPreview = true;
                break;
        }

        if (!isQFP && title === 'Materials') {
            const fieldsetNames = fieldsets.map((fieldset) => fieldset.name);
            const hingeDirection = getHingeDirection(structure);

            if (
                fieldsetNames.includes('door_edges') ||
                fieldsetNames.includes('drawer_panel_edges')
            ) {
                previewContent = (
                    <Col
                        sm={isMiniBrowser || isTabletSize ? 12 : 4}
                        id="canvas-wrapper">
                        <Sticky>
                            <DoorPreview
                                appliedPanel={true}
                                hingeDirection={hingeDirection}
                                isQFP={isQFP}
                            />
                        </Sticky>
                    </Col>
                );
                hasPreview = true;
            }
        }

        const isAdvancedDoor =
            materialOptions?.cabinet_door?.advanced ||
            (productDataStore.current.hasOwnProperty('cabinet') &&
                productDataStore.current.cabinet.hasOwnProperty('attributes') &&
                productDataStore.current.cabinet.attributes.hasOwnProperty(
                    'style'
                ) &&
                productDataStore.current.cabinet.attributes.style == 5 &&
                productDataStore.current.cabinet.attributes.hasOwnProperty(
                    'code'
                ) &&
                productDataStore.current.cabinet.attributes.code == 'Drawer');

        if (!isQFP && title === 'Drawers') {
            previewContent = (
                <Col
                    xs={isSmallDevice ? 12 : undefined}
                    sm={isTabletSize ? 12 : undefined}
                    id="canvas-wrapper">
                    <Sticky>
                        <DrawerPreview
                            isAdvanced={
                                typeof isAdvancedDoor === 'number'
                                    ? isAdvancedDoor == 1
                                    : isAdvancedDoor
                            }
                            has3DPreview={has3DPreview}
                        />
                    </Sticky>
                </Col>
            );
            hasPreview = true;
        }

        if ((!isQFP && title === 'Edging') || title === 'Faces') {
            previewContent = (
                <Col
                    sm={isMiniBrowser || isTabletSize ? 12 : 6}
                    id="canvas-wrapper">
                    <SizePreview
                        showEdges={true}
                        isMiniBrowser={isMiniBrowser}
                    />
                </Col>
            );
            hasPreview = true;
        }

        if (!isQFP && title === 'Shelves') {
            if (
                productDataStore.current.cabinet.attributes.original_name
                    .toLowerCase()
                    .indexOf('door') > -1 &&
                productDataStore.current.cabinet.attributes.original_name
                    .toLowerCase()
                    .indexOf('drawer') > -1
            ) {
                previewContent = (
                    <Col sm={isTabletSize ? 12 : 5} id="canvas-wrapper">
                        <Sticky>
                            <ShelvesPreview
                                previewOptions={structure.preview_options}
                            />
                        </Sticky>
                    </Col>
                );
                hasPreview = true;
            } else {
                let previewOptions;
                if (structure.hasOwnProperty('preview_options')) {
                    previewOptions = structure.preview_options;
                }

                previewContent = (
                    <Col sm={isTabletSize ? 12 : 5} id="canvas-wrapper">
                        <Sticky>
                            <ShelvesPreview previewOptions={previewOptions} />
                        </Sticky>
                    </Col>
                );
                hasPreview = true;
            }
        }

        return [previewContent, hasPreview];
    }, [preview, formStructure, currentTab]);

    return {
        panelPreview,
        hasPreview,
    };
};
