import {Product} from 'components/customer/Product/entity/Product';

export const mapHiddenMaterials = (product: Product) => {
    if (product.available_options) {
        if (
            !product.available_options.is_exterior_material_available &&
            product.ext_material
        ) {
            product.ext_material.hidden = true;
        }

        if (
            !product.available_options.is_exterior_edge_material_available &&
            product.ext_edge
        ) {
            product.ext_edge.hidden = true;
        }

        if (
            !product.available_options.is_carcase_material_available &&
            product.carc_material
        ) {
            product.carc_material.hidden = true;
        }

        if (
            !product.available_options.is_carcase_edge_material_available &&
            product.carc_edge
        ) {
            product.carc_edge.hidden = true;
        }

        if (!product.available_options.is_door_available && product.door) {
            product.door.hidden = true;
        }
    }

    return product;
};
