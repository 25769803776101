import React, {useMemo} from 'react';
import {Fieldset} from 'components/customer/Product/entity/Fieldset';
import {useAppContext, useProductContext} from 'contexts';
import {ProductContextType} from 'components/customer/Product/Specs';
import {useAppSelector} from 'store/customer';
import {getHasWebGLError} from 'components/customer/Preview3DCommon/store/viewerSlice';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';
import {Sticky} from 'shared/components/Sticky';
import {Preview3DComponentLazy} from 'components/customer/Preview3DCommon/components/Preview3DComponentLazy';
import NoPreview3DPlaceholder from 'components/customer/Preview3DCommon/components/NoPreview3DPlaceholder';
import {Col} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import {PreviewFormValues} from 'Preview3D/types';

export const use3dPreview = (
    fieldsets: Fieldset[],
    hasBifoldDoor: boolean,
    hasRollUpDoor: boolean
) => {
    const {userProfile} = useAppContext();
    const {values} = useFormikContext<PreviewFormValues>();

    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();
    const isSpecsTab = fieldsets.find((fieldset) => fieldset.name === 'sizes');

    const allow3DView = userProfile?.allow3DView;
    const hasWebGLError = useAppSelector(getHasWebGLError);

    const {productDataStore, isMiniBrowser, adjustableLegsQuantity} =
        useProductContext<ProductContextType>();

    const has3DPreview =
        productDataStore.current?.template_3d &&
        productDataStore.current?.template_3d?.length > 0 &&
        allow3DView;

    const show3DPreview =
        fieldsets.find((fieldset) =>
            [
                'sizes',
                'simple_shelves',
                'upper_shelves',
                'shelves',
                'winerack_hori',
            ].includes(fieldset.name)
        ) ||
        hasBifoldDoor ||
        hasRollUpDoor;

    const previewLocation = useMemo(() => {
        let location: 'DOORS' | 'SHELVES' | 'SPECS' | 'DRAWERS' = 'SPECS';

        if (fieldsets.find((fieldset) => fieldset.name === 'drawer_margins')) {
            location = 'DRAWERS';
        }

        if (
            fieldsets.find((fieldset) =>
                ['simple_shelves', 'shelves', 'upper_shelves'].includes(
                    fieldset.name
                )
            )
        ) {
            location = 'SHELVES';
        }

        if (hasBifoldDoor || hasRollUpDoor) {
            location = 'DOORS';
        }

        return location;
    }, [fieldsets, hasBifoldDoor, hasRollUpDoor]);

    const panel3DPreview = useMemo(() => {
        if (!isSpecsTab && !has3DPreview) return null;
        if ((isSpecsTab && !allow3DView) || hasWebGLError) return null;

        return (
            <Col
                xs={isSmallDevice ? 12 : undefined}
                sm={isTabletSize ? 12 : undefined}>
                <Sticky>
                    {has3DPreview ? (
                        previewLocation === 'SPECS' ? (
                            <>
                                <Preview3DComponentLazy
                                    productDataStore={productDataStore}
                                    product={values}
                                    previewLocation={previewLocation}
                                    isMiniBrowser={isMiniBrowser}
                                    adjustableLegsQuantity={
                                        adjustableLegsQuantity
                                    }
                                />
                                <Preview3DComponentLazy
                                    productDataStore={productDataStore}
                                    product={values}
                                    previewLocation={previewLocation}
                                    hiddenForScreenshot
                                    isMiniBrowser={isMiniBrowser}
                                    adjustableLegsQuantity={
                                        adjustableLegsQuantity
                                    }
                                />
                            </>
                        ) : (
                            <Preview3DComponentLazy
                                product={values}
                                productDataStore={productDataStore}
                                previewLocation={previewLocation}
                                isMiniBrowser={isMiniBrowser}
                                adjustableLegsQuantity={adjustableLegsQuantity}
                            />
                        )
                    ) : (
                        <NoPreview3DPlaceholder />
                    )}
                </Sticky>
            </Col>
        );
    }, [
        values,
        isMiniBrowser,
        previewLocation,
        isSmallDevice,
        isTabletSize,
        allow3DView,
        has3DPreview,
        isSpecsTab,
        hasWebGLError,
    ]);

    return {
        panel3DPreview,
        has3DPreview,
        show3DPreview,
        isSpecsTab,
    };
};
