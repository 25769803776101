import {useShelvesPreview} from 'hooks';
import React from 'react';

import {PreviewOptions} from 'components/customer/Product/entity/PreviewOptions';

export const ShelvesPreview = ({
    previewOptions,
}: {
    previewOptions: PreviewOptions;
}) => {
    const {position, canvasContainer} = useShelvesPreview(previewOptions);

    return (
        <div className="panelContainer" style={position} id={canvasContainer} />
    );
};
