import React, {useCallback} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useProductContext} from 'contexts';
import {BreadCrumb} from 'hooks/Product/useBreadCrumb';

interface BreadcrumbsProps {
    isHardware?: boolean;
    hideLast?: boolean;
}

interface ProductContext {
    breadcrumbs: BreadCrumb[];
    popBreadCrumb: (index?: number) => void;
}

export const Breadcrumbs = ({
    isHardware = false,
    hideLast = false,
}: BreadcrumbsProps) => {
    const {jobId} = useParams();
    const {breadcrumbs, popBreadCrumb} = useProductContext<ProductContext>();
    const location = useLocation();

    const popBreadcrumbHandler = useCallback(
        (index: number) => () => {
            popBreadCrumb(index);
        },
        [popBreadCrumb]
    );

    return (
        <ul>
            <li>
                <Link
                    onClick={popBreadcrumbHandler(-1)}
                    to={
                        isHardware
                            ? `/v2/job/${jobId}/hardware`
                            : location.pathname
                    }>
                    All Categories
                </Link>
            </li>
            {breadcrumbs.map((item, index) => {
                if (item.hasOwnProperty('link'))
                    return (
                        <li key={item.key}>
                            <Link
                                onClick={popBreadcrumbHandler(index)}
                                to={item.link}>
                                {item.key}
                            </Link>
                        </li>
                    );
                else
                    return (
                        <li
                            key={item.key}
                            style={
                                hideLast
                                    ? {
                                          display:
                                              index == breadcrumbs.length - 1
                                                  ? 'none'
                                                  : 'block',
                                      }
                                    : {}
                            }>
                            <a>{item.key}</a>
                        </li>
                    );
            })}
        </ul>
    );
};
