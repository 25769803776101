import React, {useCallback, useMemo} from 'react';
import {useProductContext} from 'contexts';
import {Col, Container, Row} from 'react-bootstrap';
import excel from 'shared/Excel';
import {useFormikContext} from 'formik';
import {Loader} from 'shared/helpers';
import {PreviewProvider} from 'components/customer/Product/Preview/PreviewContext';
import {FieldSet, FIELDSETS} from 'components/customer/Product/FieldSet';
import {Fieldset} from 'components/customer/Product/entity/Fieldset';
import {Structure} from 'components/customer/Product/entity/Structure';
import {ProductDataStore} from 'components/customer/Preview3D/usePreview3DData';
import {PreviewFormValues} from 'Preview3D/types';
import {MaterialOptions} from 'shared/types';
import {useSpecPreview} from 'components/customer/Product/helpers/useSpecPreview';
import {use3dPreview} from 'components/customer/Product/helpers/use3dPreview';
import {useMiniPreview} from 'components/customer/Product/helpers/useMiniPreview';

interface SpecsProps {
    title: string;
    preview: string;
    fieldsets: Fieldset[];
    structure: Structure;
    isQFP?: boolean;
    className?: string;
}

export interface ProductContextType {
    productDataStore: ProductDataStore;
    getMaterialOptions: () => MaterialOptions;
    isLoading: boolean;
    formStructure: Structure[];
    currentTab: number;
    isMiniBrowser: boolean;
    doorPreviewOpacity: number;
    setDoorPreviewOpacity: (opacity: number) => void;
    setPositionType: (name: string, type: string) => void;
    resetNewShelf: () => void;
    newShelfStore: React.RefObject<{autoPosition: boolean; position: number}>;
    setHeightReference: (key: string, value: string) => void;
    shelvesHeightUpdated: boolean;
    shelvesHeightReference: React.RefObject<{[key: string]: string}>;
    simpleShelvesReference: number;
    shelvesUpdatedReference: React.RefObject<{[key: string]: boolean}>;
    adjustableLegsQuantity: number;
}

export const Specs = ({
    title,
    preview = 'standard',
    fieldsets,
    structure,
    isQFP = false,
    className = null,
}: SpecsProps) => {
    const {
        productDataStore,
        getMaterialOptions,
        isLoading,
        formStructure,
        currentTab,
        isMiniBrowser,
        doorPreviewOpacity,
        setDoorPreviewOpacity,
    } = useProductContext<ProductContextType>();
    const {values, setFieldValue} = useFormikContext<PreviewFormValues>();

    const checkCustomDoorHang = (doorHangName: string) => {
        const doorFields = fieldsets.find(
            (fieldset) => fieldset.name === 'door_fields'
        );

        if (doorFields) {
            const doorHang = doorFields?.fields?.find(
                (doorField) => doorField.name === 'door_hang'
            );

            if (doorHang) {
                return (
                    (doorHang.value as string)
                        ?.toLowerCase()
                        ?.indexOf(doorHangName) > -1
                );
            }
        }

        return false;
    };

    const hasBifoldDoor = useMemo(() => {
        return checkCustomDoorHang('bifold');
    }, [fieldsets]);

    const hasRollUpDoor = useMemo(() => {
        return checkCustomDoorHang('rollup');
    }, [fieldsets]);

    const {panelPreview, hasPreview} = useSpecPreview(
        title,
        preview,
        structure,
        fieldsets,
        isQFP
    );
    const {isSpecsTab, has3DPreview, show3DPreview, panel3DPreview} =
        use3dPreview(fieldsets, hasBifoldDoor, hasRollUpDoor);
    useMiniPreview(
        title,
        panelPreview,
        panel3DPreview,
        !!show3DPreview,
        hasPreview
    );

    const filteredFieldsets = fieldsets.filter((fieldset) => {
        if (
            fieldset.hasOwnProperty('options') &&
            fieldset.options.hasOwnProperty('visible')
        ) {
            let visibleCondition = fieldset.options.visible;

            if (
                !isNaN(Number(visibleCondition)) &&
                typeof visibleCondition !== 'boolean'
            ) {
                visibleCondition = parseInt(String(visibleCondition)) > 0;
            }

            if (
                isNaN(Number(visibleCondition)) &&
                typeof visibleCondition === 'string'
            ) {
                try {
                    visibleCondition = excel.calculate(
                        visibleCondition,
                        values
                    );
                } catch (error) {
                    visibleCondition = fieldset.options.visible;
                }
            }

            if (typeof visibleCondition === 'boolean') {
                if (!visibleCondition) return false;
            } else {
                const isVisible =
                    !isQFP && getMaterialOptions()?.cabinet_door?.advanced;

                if (!isVisible) return false;
            }
        }

        return true;
    });

    const fieldValueHandler = useCallback(
        (name: string, value: string | number | boolean) => {
            void setFieldValue(name, value);
        },
        [setFieldValue]
    );

    const renderFieldSet = (fieldset: Fieldset, index: number) => {
        return (
            <FieldSet
                key={index}
                index={index}
                hasPreview={hasPreview || has3DPreview}
                fieldset={fieldset}
                values={values}
                showFieldsetTitle={fieldset.name !== FIELDSETS.MATERIALS}
                setFieldValue={fieldValueHandler}
            />
        );
    };

    let drawerRunners = null;
    let additionals = null;
    const drawerRunnersFilter = (fieldset: Fieldset) =>
        fieldset.name == 'drawer_runners';
    const additionalFilter = (fieldset: Fieldset) =>
        fieldset.name == 'additional' || fieldset.name == 'variations';

    if (title === 'Drawers')
        drawerRunners = filteredFieldsets.filter(drawerRunnersFilter);

    if (isSpecsTab) additionals = filteredFieldsets.filter(additionalFilter);

    const isMaterialTab = fieldsets.find(
        (fieldset) => fieldset.name === 'materials'
    );

    return (
        <Loader loader={isLoading} relative={isMiniBrowser}>
            <Container className={className == null ? '' : className}>
                <Row>
                    <Col md={isMaterialTab ? 12 : undefined}>
                        {filteredFieldsets
                            .filter(
                                (fieldset) => !drawerRunnersFilter(fieldset)
                            )
                            .filter((fieldset) => !additionalFilter(fieldset))
                            .map(renderFieldSet)}
                    </Col>
                    {isMiniBrowser ? null : show3DPreview && panel3DPreview ? (
                        panel3DPreview
                    ) : (
                        <PreviewProvider
                            product={values}
                            formStructure={formStructure}
                            currentTab={currentTab}
                            doorPreviewOpacity={doorPreviewOpacity}
                            setDoorPreviewOpacity={setDoorPreviewOpacity}
                            productDataStore={productDataStore}
                            isMiniBrowser={isMiniBrowser}
                            getMaterialOptions={getMaterialOptions}>
                            {panelPreview}
                        </PreviewProvider>
                    )}
                </Row>
                {drawerRunners ? (
                    <>
                        <hr />
                        <Row>
                            <Col xs={12}>
                                {drawerRunners.map(renderFieldSet)}
                            </Col>
                        </Row>
                    </>
                ) : null}
                {additionals ? (
                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>{additionals.map(renderFieldSet)}</Col>
                    </Row>
                ) : null}
            </Container>
        </Loader>
    );
};
