import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Shimmer} from 'shared/components/Shimmer';
import styled from 'styled-components';

export const PlaceholderLoader = () => {
    return (
        <div>
            <Row>
                <Col>
                    <FauxTabs />
                </Col>
                <Col>
                    <FauxTabs />
                </Col>
                <Col>
                    <FauxTabs />
                </Col>
                <Col>
                    <FauxTabs />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FauxField />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FauxField />
                </Col>
                <Col>
                    <FauxField />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FauxField />
                </Col>
                <Col>
                    <FauxField />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FauxField />
                </Col>
                <Col>
                    <FauxField />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FauxField />
                </Col>
                <Col>
                    <FauxField />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FauxField />
                </Col>
                <Col>
                    <FauxField />
                </Col>
            </Row>

            <FauxContainres />
        </div>
    );
};

const FauxTabs = styled(Shimmer)`
    height: 30px;
    border-radius: 8px;
`;

const FauxField = styled(Shimmer)`
    height: 30px;
    border-radius: 8px;
    margin: 15px 0;
`;

const FauxContainres = styled(Shimmer)`
    height: 225px;
    margin-top: 15px;
    border-radius: 8px;
`;
