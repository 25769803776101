import React from 'react';
import {useProductCanvasPreview} from 'hooks/Product/Product';

interface SizePreviewProps {
    showEdges?: boolean;
    isMiniBrowser?: boolean;
}

export const SizePreview = ({
    showEdges = false,
    isMiniBrowser = false,
}: SizePreviewProps) => {
    const {position, canvasContainerId} = useProductCanvasPreview(showEdges);

    const style = {
        ...position,
    };

    if (isMiniBrowser) {
        style.width = '320px';
        style.textAlign = 'center';
    }

    return (
        <div className="panelContainer" style={style} id={canvasContainerId} />
    );
};
