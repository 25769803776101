import {Product as ProductInterface} from 'components/customer/Product/entity/Product';
import {PartialRoom} from 'shared/types/PartialRoom';
import {BaseBottomMargin, DrawerGap, LeftGap, RightGap} from 'assets';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';
import {DrawerSystem} from 'shared/types/DrawerSystem';

export const getDrawerSelection = ({
    product,
    room,
    cabinetFormFieldDefaults,
    drawerSystem: drawerSystems,
    measurement,
}: {
    product: ProductInterface;
    room: PartialRoom;
    cabinetFormFieldDefaults: Record<string, string | number>;
    drawerSystem: DrawerSystem[];
    measurement: string;
}): SelectionInterface[] => {
    const rules = [];

    const changedDrawerType =
        product.drawer_type &&
        product.drawer_type.find((item) => item != room.drawerStyle);

    if (changedDrawerType) {
        const selectedDrawer =
            drawerSystems &&
            drawerSystems.find((drawer) => drawer.id == changedDrawerType);

        const roomDrawerStyle =
            drawerSystems &&
            drawerSystems.find((drawer) => drawer.id == room.drawerStyle);
        if (
            'hiddenFromRoom' in selectedDrawer &&
            !selectedDrawer.hiddenFromRoom
        ) {
            rules.push({
                key: 'drawer_style',
                name: 'Drawer Style',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isDrawerStyleSet,
                set_key: 'isDrawerStyleSet',
                room_data: roomDrawerStyle
                    ? roomDrawerStyle.name
                    : 'Unavailable',
                product_data: selectedDrawer.name,
                value: changedDrawerType,
                image: `/${selectedDrawer.image}`,
                apply_to_room: true,
            });
        }
    }
    if (typeof product.cabinet_drawer_gap != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_drawer_gap !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_drawer_gap !=
                    product.cabinet_drawer_gap &&
                String(product.cabinet_drawer_gap) != room.drawerGap) ||
            (typeof cabinetFormFieldDefaults.cabinet_drawer_gap ==
                'undefined' &&
                String(product.cabinet_drawer_gap) != room.drawerGap)
        ) {
            rules.push({
                key: 'drawer_gap',
                name: 'Drawer Gap',
                is_saved:
                    room.customerOptions && room.customerOptions.isDrawerGapSet,
                set_key: 'isDrawerGapSet',
                room_data: `${room.drawerGap} ${measurement}`,
                product_data: `${product.cabinet_drawer_gap} ${measurement}`,
                value: product.cabinet_drawer_gap,
                image: String(DrawerGap),
                apply_to_room: true,
            });
        }
    }

    if (typeof product.cabinet_drawer_left != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_drawer_left !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_drawer_left !=
                    product.cabinet_drawer_left &&
                product.cabinet_drawer_left != room.leftGap) ||
            (typeof cabinetFormFieldDefaults.cabinet_drawer_left ==
                'undefined' &&
                product.cabinet_drawer_left != room.leftGap)
        ) {
            rules.push({
                key: 'left_gap',
                name: 'Left Gap',
                is_saved:
                    room.customerOptions && room.customerOptions.isLeftGapSet,
                set_key: 'isLeftGapSet',
                room_data: `${room.leftGap} ${measurement}`,
                product_data: `${product.cabinet_drawer_left} ${measurement}`,
                value: product.cabinet_drawer_left,
                image: String(LeftGap),
                apply_to_room: true,
            });
        }
    }

    if (typeof product.cabinet_drawer_right != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_drawer_right !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_drawer_right !=
                    product.cabinet_drawer_right &&
                product.cabinet_drawer_right != room.rightGap) ||
            (typeof cabinetFormFieldDefaults.cabinet_drawer_right ==
                'undefined' &&
                product.cabinet_drawer_right != room.rightGap)
        ) {
            rules.push({
                key: 'right_gap',
                name: 'Right Gap',
                is_saved:
                    room.customerOptions && room.customerOptions.isRightGapSet,
                set_key: 'isRightGapSet',
                room_data: `${room.rightGap} ${measurement}`,
                product_data: `${product.cabinet_drawer_right} ${measurement}`,
                value: product.cabinet_drawer_right,
                image: String(RightGap),
                apply_to_room: true,
            });
        }
    }

    if (typeof product.cabinet_drawer_bottom != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.cabinet_drawer_bottom !=
                    'undefined' &&
                cabinetFormFieldDefaults.cabinet_drawer_bottom !=
                    product.cabinet_drawer_bottom &&
                product.cabinet_drawer_bottom != room.baseBottomGap) ||
            (typeof cabinetFormFieldDefaults.cabinet_drawer_bottom ==
                'undefined' &&
                product.cabinet_drawer_bottom != room.baseBottomGap)
        ) {
            rules.push({
                key: 'base_bottom_gap',
                name: 'Base Bottom Margin',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBaseBottomMarginSet,
                set_key: 'isBaseBottomMarginSet',
                room_data: `${room.baseBottomGap} ${measurement}`,
                product_data: `${product.cabinet_drawer_bottom} ${measurement}`,
                value: product.cabinet_drawer_bottom,
                image: String(BaseBottomMargin),
                apply_to_room: true,
            });
        }
    }

    return rules;
};
