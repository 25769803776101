import {PreviewFormValues} from 'Preview3D/types';
import {ProductDataStore} from 'components/customer/Preview3D/usePreview3DData';
import React, {createContext, useContext} from 'react';
import {Structure} from 'components/customer/Product/entity/Structure';
import {MaterialOptions} from 'shared/types';

interface PreviewContextProps extends React.PropsWithChildren<object> {
    product?: PreviewFormValues;
    doorPreviewOpacity?: number;
    setDoorPreviewOpacity?: (opacity: number) => void;
    productDataStore?: ProductDataStore;
    isMiniBrowser?: boolean;
    getMaterialOptions?: () => MaterialOptions;
    formStructure?: Structure[];
    currentTab?: number;
    adjustableLegsQuantity?: number;
}

interface PreviewContextType {
    values?: PreviewFormValues;
    doorPreviewOpacity?: number;
    setDoorPreviewOpacity?: (opacity: number) => void;
    productDataStore?: ProductDataStore;
    isMiniBrowser?: boolean;
    getMaterialOptions?: () => MaterialOptions;
    formStructure?: Structure[];
    currentTab?: number;
    adjustableLegsQuantity?: number;
}

const PreviewContext = createContext<PreviewContextType>({});

export const usePreviewContext = () => useContext(PreviewContext);

export const PreviewProvider = ({
    children,
    product,
    ...props
}: PreviewContextProps) => {
    return (
        <PreviewContext.Provider value={{values: product, ...props}}>
            {children}
        </PreviewContext.Provider>
    );
};
