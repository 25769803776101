import React from 'react';
import {GenericFields} from 'components/customer/Product';
import styled from 'styled-components';
import {useProductContext} from 'contexts';
import {Field} from 'components/customer/Product/entity/Field';

const StyledContainer = styled.div<{$isMiniBrowser: boolean}>`
    ${({$isMiniBrowser}) => {
        return `display: flex;
        flex-wrap: wrap;
        gap: 10px 25px;
        margin-bottom: 20px;
        width: ${$isMiniBrowser ? '100%' : 'auto'};
    
        & > .row {
            margin-left: 0;
            width: ${$isMiniBrowser ? '100%' : 'auto'};
            flex-basis: ${$isMiniBrowser ? '100%' : 'calc(50% - 20px)'};
            background: #eee;
            border-radius: 8px;
            &.include_drawer_faces_label {
                background: #fff;
                width: 100%;
                order: 1;
            }
        }`;
    }}
`;

interface ProductionOptionsProps {
    fields: Field[];
    fieldsetName: string;
}

export const ProductionOptions = ({
    fields,
    fieldsetName,
}: ProductionOptionsProps) => {
    const {isMiniBrowser} = useProductContext<{isMiniBrowser: boolean}>();

    return (
        <>
            <hr />
            <StyledContainer $isMiniBrowser={isMiniBrowser}>
                <GenericFields
                    fields={fields}
                    fieldsetName={fieldsetName}
                    formLabelSpanOverride={12}
                    colSpanFull
                />
            </StyledContainer>
        </>
    );
};
