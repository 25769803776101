import {PartialRoom} from 'shared/types/PartialRoom';
import {getHardwareSelection} from 'components/customer/Product/helpers/getHardwareSeletion';
import {getSupplyDefaultSelection} from 'components/customer/Product/helpers/getSupplyDefaultSelection';
import {getBaseProductSelection} from 'components/customer/Product/helpers/getBaseProductSelection';
import {getTallProductSelection} from 'components/customer/Product/helpers/getTallProductSelection';
import {getUpperProductSelection} from 'components/customer/Product/helpers/getUpperProductSelection';
import {getBorderSelection} from 'components/customer/Product/helpers/getBorderSelection';
import {getExteriorMaterialSelection} from 'components/customer/Product/helpers/getExteriorMaterialSelection';
import {getCarcaseMaterialSelection} from 'components/customer/Product/helpers/getCarcaseMaterialSelection';
import {getDoorSelection} from 'components/customer/Product/helpers/getDoorSelection';
import {getRailSelection} from 'components/customer/Product/helpers/getRailSelection';
import {getDrawerSelection} from 'components/customer/Product/helpers/getDrawerSelection';
import {getToeKickSelection} from 'components/customer/Product/helpers/getToeKickSelection';
import {User} from 'components/customer/Auth/store/userSlice';
import {getShelfSelection} from 'components/customer/Product/helpers/getShelfSelection';
import {
    Material,
    MaterialEdge,
    Door,
} from 'components/customer/Materials/entity';
import {DrawerSystem} from 'shared/types/DrawerSystem';
import {HardwareOption} from 'shared/types/HardwareOption';
import {HingeStyle} from 'shared/types/HingeStyle';
import {ProductDefault} from 'components/customer/Product/entity/ProductDefault';
import {Product as ProductInterface} from 'components/customer/Product/entity/Product';

export const getChangedData =
    (
        defaultsValues: ProductDefault,
        drawerSystem: DrawerSystem[],
        hingeStyles: HingeStyle[],
        roomHingeStyles: HingeStyle[],
        hardwareOptions: HardwareOption[],
        userProfile: User
    ) =>
    ({
        product,
        room,
        materials,
    }: {
        product: ProductInterface;
        room: PartialRoom;
        materials: {
            ext: Material;
            carc: Material;
            extEdge: MaterialEdge;
            carcEdge: MaterialEdge;
            door: Door;
        };
    }) => {
        const {cabinet_form_field_defaults: cabinetFormFieldDefaults} =
            defaultsValues;
        const measurement =
            userProfile?.defaultMeasurementUnit === 'METRIC' ? 'mm' : 'inches';
        const exteriorMaterialRules = getExteriorMaterialSelection({
            room,
            cabinetFormFieldDefaults,
            measurement,
            extEdge: materials.extEdge,
            ext: materials.ext,
            doubleSided: product.double_sided_ext,
            horizontalGrain: product.hor_grain_ext,
            customColour: product.custom_colour_ext,
        });

        return [
            {
                category: 'Hardware Selection',
                items: getHardwareSelection({
                    product,
                    room,
                    hingeStyles,
                    roomHingeStyles,
                }),
            },
            {
                category: 'Supply Default Selection',
                items: getSupplyDefaultSelection({
                    product,
                    room,
                    hardwareOptions,
                    cabinetFormFieldDefaults,
                }),
            },
            {
                category: 'Border Selection',
                items: getBorderSelection({
                    product,
                    room,
                    cabinetFormFieldDefaults,
                    measurement,
                }),
            },
            {
                category: 'Exterior Material Selection',
                items: exteriorMaterialRules,
            },
            {
                category: 'Carcase Selection Selection',
                items: getCarcaseMaterialSelection({
                    room,
                    cabinetFormFieldDefaults,
                    measurement,
                    carcEdge: materials.carcEdge,
                    carc: materials.carc,
                    horizontalGrain: product.hor_grain_carc,
                    customColour: product.custom_colour_ext,
                }),
            },
            {
                category: 'Door Selection',
                items: getDoorSelection({
                    product,
                    room,
                    exteriorMaterialRules,
                    cabinetFormFieldDefaults,
                    measurement,
                    door: materials.door,
                }),
            },
            {
                category: 'Rail Selection',
                items: getRailSelection({
                    product,
                    room,
                    cabinetFormFieldDefaults,
                    measurement,
                }),
            },
            {
                category: 'Toe Kick Selection',
                items: getToeKickSelection({
                    product,
                    room,
                    cabinetFormFieldDefaults,
                    measurement,
                }),
            },
            {
                category: 'Drawer Selection',
                items: getDrawerSelection({
                    product,
                    room,
                    cabinetFormFieldDefaults,
                    drawerSystem,
                    measurement,
                }),
            },
            {
                category: 'Shelf Selection',
                items: getShelfSelection({
                    product,
                    room,
                    measurement,
                }),
            },
            {
                category: 'Base Product Selection',
                items: getBaseProductSelection({
                    product,
                    room,
                    cabinetFormFieldDefaults,
                    measurement,
                }),
            },
            {
                category: 'Upper Product Selection',
                items: getUpperProductSelection({
                    product,
                    room,
                    cabinetFormFieldDefaults,
                    measurement,
                }),
            },
            {
                category: 'Tall Product Selection',
                items: getTallProductSelection({
                    product,
                    room,
                    cabinetFormFieldDefaults,
                    measurement,
                }),
            },
        ].filter((item) => item.items && item.items.length > 0);
    };
