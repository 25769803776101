import React, {useCallback, useEffect, useMemo} from 'react';
import {ProductContextType} from 'components/customer/Product/Specs';
import {useProductContext} from 'contexts';
import {
    setDoorPreview,
    setDrawerPreview,
    setEdgePreview,
    setNoPreview,
    setPreview,
    setShelvesPreview,
    setShow3DPreview,
    setShowDoorPreview,
    setShowDrawerPreview,
    setShowEdgePreview,
    setShowShelvesPreview,
} from 'components/customer/RoomPlanner/helper/useRoomPlannerPreview';
import {PreviewProvider} from 'components/customer/Product/Preview/PreviewContext';
import {useFormikContext} from 'formik';
import {PreviewFormValues} from 'Preview3D/types';

export const useMiniPreview = (
    title: string,
    preview2d: React.ReactNode,
    preview3d: React.ReactNode,
    show3DPreview: boolean,
    show2DPreview: boolean
) => {
    const {
        isMiniBrowser,
        currentTab,
        formStructure,
        doorPreviewOpacity,
        setDoorPreviewOpacity,
        productDataStore,
        getMaterialOptions,
        adjustableLegsQuantity,
    } = useProductContext<ProductContextType>();
    const {values} = useFormikContext<PreviewFormValues>();

    const withProvider = useCallback(
        (preview: React.ReactNode) => {
            return (
                <PreviewProvider
                    product={values}
                    formStructure={formStructure}
                    currentTab={currentTab}
                    doorPreviewOpacity={doorPreviewOpacity}
                    setDoorPreviewOpacity={setDoorPreviewOpacity}
                    productDataStore={productDataStore}
                    isMiniBrowser={isMiniBrowser}
                    getMaterialOptions={getMaterialOptions}
                    adjustableLegsQuantity={adjustableLegsQuantity}>
                    {preview}
                </PreviewProvider>
            );
        },
        [
            values,
            formStructure,
            currentTab,
            doorPreviewOpacity,
            productDataStore,
            isMiniBrowser,
            getMaterialOptions,
        ]
    );

    useEffect(() => {
        if (isMiniBrowser) {
            switch (title) {
                case 'Specs':
                    setPreview(withProvider(preview3d ? preview3d : preview2d));
                    break;
                case 'Materials':
                    setDoorPreview(withProvider(preview2d));
                    break;
                case 'Faces':
                case 'Edging':
                    setEdgePreview(withProvider(preview2d));
                    break;
                case 'Doors':
                    setDoorPreview(withProvider(preview2d));
                    break;
                case 'Drawers':
                    setDrawerPreview(withProvider(preview2d));
                    break;
                case 'Shelves':
                    setShelvesPreview(
                        withProvider(preview3d ? preview3d : preview2d)
                    );
                    break;
                default:
                    break;
            }
        }
    }, [
        isMiniBrowser,
        title,
        preview2d,
        preview3d,
        values,
        doorPreviewOpacity,
    ]);

    useEffect(() => {
        return () => {
            setPreview(null);
            setEdgePreview(null);
            setDoorPreview(null);
            setDrawerPreview(null);
            setShelvesPreview(null);
        };
    }, []);

    const hasBothDoorsAndDrawers = useMemo(() => {
        const fieldsetNames = formStructure.map((fieldset) =>
            fieldset.name.toLowerCase()
        );

        return (
            fieldsetNames.includes('doors') && fieldsetNames.includes('drawers')
        );
    }, [formStructure]);

    useEffect(() => {
        if (isMiniBrowser) {
            const SPEC_TAB = 0;
            const MATERIALS_TAB = 1;
            const FACES_TAB = 2;
            const DOORS_TAB = hasBothDoorsAndDrawers ? 3 : 2;
            const DRAWERS_TAB = 2;
            const SHELVES_TAB = hasBothDoorsAndDrawers ? 4 : 3;
            const EDGING_TAB = 2;

            if (currentTab == SPEC_TAB && title === 'Specs') {
                if (show3DPreview) {
                    setShow3DPreview();
                } else {
                    setNoPreview();
                }
            } else if (currentTab == MATERIALS_TAB && title === 'Materials') {
                if (show2DPreview) {
                    setShowDoorPreview();
                } else {
                    setShow3DPreview();
                }
            } else if (currentTab == FACES_TAB && title === 'Faces') {
                if (show3DPreview) setShow3DPreview();
                else if (show2DPreview) setShowEdgePreview();
                else {
                    setNoPreview();
                }
            } else if (currentTab == EDGING_TAB && title === 'Edging') {
                if (show3DPreview) setShow3DPreview();
                else if (show2DPreview) setShowEdgePreview();
                else {
                    setNoPreview();
                }
            } else if (currentTab == DOORS_TAB && title === 'Doors') {
                if (show3DPreview) setShow3DPreview();
                else if (show2DPreview) setShowDoorPreview();
                else {
                    setNoPreview();
                }
            } else if (currentTab == DRAWERS_TAB && title === 'Drawers') {
                if (show3DPreview) setShow3DPreview();
                else if (show2DPreview) setShowDrawerPreview();
                else {
                    setNoPreview();
                }
            } else if (currentTab == SHELVES_TAB && title === 'Shelves') {
                if (preview3d || show3DPreview) {
                    setShow3DPreview();
                } else if (show2DPreview) {
                    setShowShelvesPreview();
                } else {
                    setNoPreview();
                }
            }
        }
    }, [currentTab, title, hasBothDoorsAndDrawers]);
};
