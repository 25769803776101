import {Product as ProductInterface} from 'components/customer/Product/entity/Product';
import {PartialRoom} from 'shared/types/PartialRoom';
import {BaseHeight, BaseDepth} from 'assets';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';
import {ProductType} from './entity/ProductType';

export const getBaseProductSelection = ({
    product,
    room,
    cabinetFormFieldDefaults,
    measurement,
}: {
    product: ProductInterface;
    room: PartialRoom;
    cabinetFormFieldDefaults: Record<string, string | number>;
    measurement: string;
}): SelectionInterface[] => {
    const rules = [];
    if (product.cabinet_style != ProductType.BASE) {
        return null;
    }
    if (typeof product.cabinet_height != 'undefined') {
        const data = {
            key: 'base_height',
            name: 'Base Height',
            is_saved:
                room.customerOptions && room.customerOptions.isBaseHeightSet,
            set_key: 'isBaseHeightSet',
            room_data: `${room.baseHeight} ${measurement}`,
            product_data: `${product.cabinet_height} ${measurement}`,
            value: product.cabinet_height,
            image: String(BaseHeight),
            apply_to_room: true,
        };
        if (
            (typeof cabinetFormFieldDefaults.cabinet_height != 'undefined' &&
                cabinetFormFieldDefaults.cabinet_height !=
                    product.cabinet_height &&
                product.cabinet_height != room.baseHeight) ||
            (typeof cabinetFormFieldDefaults.cabinet_height == 'undefined' &&
                product.cabinet_height != room.baseHeight)
        ) {
            rules.push(data);
        }
    }

    if (typeof product.cabinet_depth != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults.cabinet_depth != 'undefined' &&
                cabinetFormFieldDefaults.cabinet_depth !=
                    product.cabinet_depth &&
                product.cabinet_depth != parseInt(room.baseDepth)) ||
            (typeof cabinetFormFieldDefaults.cabinet_depth == 'undefined' &&
                product.cabinet_depth != parseInt(room.baseDepth))
        ) {
            rules.push({
                key: 'base_depth',
                name: 'Base Depth',
                is_saved:
                    room.customerOptions && room.customerOptions.isBaseDepthSet,
                set_key: 'isBaseDepthSet',
                room_data: `${room.baseDepth} ${measurement}`,
                product_data: `${product.cabinet_depth} ${measurement}`,
                value: product.cabinet_depth,
                image: String(BaseDepth),
                apply_to_room: true,
            });
        }
    }

    return rules;
};
