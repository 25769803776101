import React, {FC, memo, useMemo} from 'react';
import {Button, Col} from 'react-bootstrap';
import styled from 'styled-components';
import {isDeviceSmall, isDeviceMid} from 'shared/helpers/DeviceSize';
import {useProductContext} from 'contexts';
import {ProductBackgroundImage} from 'components/customer/Product/ProductStyled';
import ProductInclusion from 'components/customer/Product/Description/ProductInclusion';
import {Specs} from 'components/customer/Product/Specs';
import {Product} from 'components/customer/Product/entity/Product';
import {ProductDataStore} from 'components/customer/Preview3D/usePreview3DData';

const NoteWrapper = styled.div<{
    $isMobile: boolean;
    $isMidSizedDevice: boolean;
}>`
    margin-left: ${({$isMobile, $isMidSizedDevice}) =>
        $isMobile ? '0' : $isMidSizedDevice ? '20px' : '25px'};
    width: ${({$isMobile}) => ($isMobile ? '272px' : '250px')};
    ${({$isMidSizedDevice}) =>
        $isMidSizedDevice ? 'padding-right: 30px;' : ''}
    margin-top: 1rem;
    .more-info {
        left: 90px;
    }
    .notes-and-variations {
        margin-bottom: 5px !important;
    }
    #notes-id {
        .cancel-button-container img {
            top: ${({$isMobile}) => ($isMobile ? '-36px' : '0')};
            left: 45px;
        }
        .col-8 {
            flex: 1;
            flex-grow: 1;
            max-width: 100%;
        }
    }
    .col-sm-3 {
        display: none;
    }
    @media (min-width: 768px) and (max-width: 1550px) {
        width: 220px;
        margin-left: 0px;
        padding-right: 10px;
        #notes-id.expanded {
            margin-right: -10px;
        }
    }
`;

interface DynamicImageProps {
    productData: any;
    productConfig: any[];
    inSizesTab: boolean;
    addToFavourite: any;
    isFavourite: any;
    favouriteButtonDisabled: any;
    formValues: any;
    manufacturerDescription?: JSX.Element;
}

const DynamicImage: FC<DynamicImageProps> = ({
    productData,
    productConfig,
    inSizesTab,
    addToFavourite,
    isFavourite,
    favouriteButtonDisabled,
    formValues = {},
    manufacturerDescription,
}) => {
    const midSizedDevice = isDeviceMid();
    const isMobile = isDeviceSmall();

    const {getProductInfo, productDataStore, getMaterialOptions} =
        useProductContext<{
            getProductInfo: (key: string) => string;
            productDataStore: {current?: Product} & ProductDataStore;
            getMaterialOptions: () => {
                exterior_color: string;
                hor_grain_ext: number;
            };
        }>();

    const materialOptions = getMaterialOptions();

    const favouriteButtonClassName = inSizesTab
        ? `itemFavouriteInSizesTab${isMobile ? ' leftInherit' : ''}${
              midSizedDevice ? ' leftReduced' : ''
          }`
        : 'itemFavourite';

    const sidebarTab = useMemo(
        () => productConfig.find((tab) => tab?.layout == 'sidebar'),
        [productConfig]
    );

    const isSvg = getProductInfo('image').endsWith('.svg');
    const is3DImageLoading = Boolean(
        productDataStore?.current?.is_cabinet_3d_image_loading
    );

    return (
        <Col
            className="product-images"
            md={{offset: 0, span: 12}}
            xs={{offset: 2, span: 8}}>
            <Button
                onClick={addToFavourite}
                disabled={favouriteButtonDisabled}
                variant="btn btn-link"
                className={
                    isFavourite
                        ? `${favouriteButtonClassName} userFavourite`
                        : favouriteButtonClassName
                }
            />

            <div
                className={`productImageContainer ${
                    inSizesTab ? 'imageContainerInSizesTab' : 'imageContainer'
                }`}>
                <div style={{width: '150px', height: '150px'}}>
                    <ProductBackgroundImage
                        image={
                            !isSvg &&
                            `url('${materialOptions['exterior_color']}')`
                        }
                        transform={!isSvg && materialOptions['hor_grain_ext']}
                        src={
                            productDataStore?.current?.cabinet_image
                                ? `${
                                      productDataStore?.current?.cabinet_image
                                  }?cache_bust=${Date.now()}`
                                : undefined
                        }
                        fallbackSrc={getProductInfo('image')}
                        alt={getProductInfo('typeName')}
                        cachingInProgress={is3DImageLoading}
                    />
                </div>

                <span className="product-name">{getProductInfo('name')}</span>

                {sidebarTab ? (
                    <NoteWrapper
                        $isMobile={isMobile}
                        $isMidSizedDevice={midSizedDevice}>
                        <Specs
                            title={sidebarTab.name}
                            preview={sidebarTab.preview}
                            fieldsets={sidebarTab.fieldsets}
                            structure={sidebarTab}
                        />
                    </NoteWrapper>
                ) : (
                    <></>
                )}

                <span className="product-inclusions">
                    <ProductInclusion
                        formValues={formValues}
                        productData={productData}
                        productConfig={productConfig}
                        productDataStore={productDataStore}
                        manufacturerDescription={manufacturerDescription}
                    />
                </span>
            </div>
        </Col>
    );
};

export default memo(DynamicImage);
