import React from 'react';
import {ProductProvider} from 'contexts';

import './product.scss';
import {
    Products,
    ProductsProps,
} from 'components/customer/Product/ProductsWithProvider';

export const ProductWrapper = ({
    showTitle = true,
    showBreadcrumbs = true,
}: ProductsProps) => {
    return (
        <ProductProvider miniBrowser={!showTitle}>
            <Products
                showTitle={showTitle}
                showBreadcrumbs={showBreadcrumbs}
                isMiniBrowser={!showTitle || !showBreadcrumbs}
            />
        </ProductProvider>
    );
};

export default ProductWrapper;
