import {usePositions} from 'hooks';
import React, {useCallback, useMemo} from 'react';
import {Form, FormGroup} from 'react-bootstrap';
import {CBCButton} from 'shared/helpers';

import styled from 'styled-components';
import {FIELDSET_LAYOUT, GenericFields} from 'components/customer/Product';
import {FormikErrors, useFormikContext} from 'formik';
import {Fieldset} from 'components/customer/Product/entity/Fieldset';

interface MidRailsProps {
    fieldset: Fieldset;
    selectHandler?: () => void;
    isQFP?: boolean;
}

interface MidRailsValues {
    hori_amount: number;
    vert_amount: number;
}

const ButtonContainer = styled.div<{$isQFP?: boolean}>`
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    & > button {
        font-size: 0.8rem;
        width: 172px !important;
        padding-right: 8px !important;
        height: 37px !important;
        & > img {
            width: 29px !important;
            margin-left: ${({$isQFP}) => ($isQFP ? '4px' : '-2px')};
        }
    }
`;

export const MidRails = ({
    fieldset,
    selectHandler,
    isQFP = false,
}: MidRailsProps) => {
    const {setFieldValue, values} = useFormikContext<MidRailsValues>();
    const {
        horizontal_rail_quantity_field: horiAmount,
        vertical_rail_quantity_field: vertAmount,
        horizontal_rail_height_field: horiHeight,
        vertical_rail_height_field: vertHeight,
        horizontal_rail_position_field: horiPosition,
        vertical_rail_position_field: vertPosition,
    } = fieldset.options;
    const {
        horizontalRail,
        horizontalHeight,
        horizontalAmount,
        verticalRail,
        verticalWidth,
        verticalAmount,
        horizontalMoreInfo,
        verticalMoreInfo,
    } = useMemo(() => {
        const customWidth = 'calc(100% - 38px)'; // 38px offset for delete button
        const horizontalHeight = fieldset.fields.find(
            (field) => field.name === horiHeight
        );
        const verticalWidth = fieldset.fields.find(
            (field) => field.name === vertHeight
        );
        horizontalHeight.options['customWidth'] = customWidth;
        verticalWidth.options['customWidth'] = customWidth;
        return {
            horizontalHeight,
            verticalWidth,
            horizontalRail: fieldset.fields.find(
                (field) => field.name === horiPosition
            ),
            horizontalAmount: fieldset.fields.find(
                (field) => field.name === horiAmount
            ),
            verticalRail: fieldset.fields.find(
                (field) => field.name === vertPosition
            ),
            verticalAmount: fieldset.fields.find(
                (field) => field.name === vertAmount
            ),
            horizontalMoreInfo: fieldset.options?.horizontal_more_info,
            verticalMoreInfo: fieldset.options?.vertical_more_info,
        };
    }, [fieldset]);

    const handleButtonClick = useCallback(
        (
                setFieldValue: (
                    field: string,
                    value: boolean | number | string,
                    shouldValidate?: boolean
                ) => Promise<void | FormikErrors<MidRailsValues>>,
                fieldName: string,
                currentValue: number
            ) =>
            () => {
                void setFieldValue(
                    fieldName,
                    currentValue ? currentValue + 1 : 1
                );
            },
        []
    );

    const {positions: horizontalPositions} = usePositions(
        horizontalRail?.name,
        'hori_mid_rail_positions',
        fieldset,
        isQFP,
        selectHandler
    );

    const {positions: verticalPositions} = usePositions(
        verticalRail?.name,
        'vert_mid_rail_positions',
        fieldset,
        isQFP,
        selectHandler
    );

    const horizontalRailFields =
        horizontalPositions.length > 0
            ? [horizontalHeight, ...horizontalPositions]
            : [];

    const verticalRailFields =
        verticalPositions.length > 0
            ? [verticalWidth, ...verticalPositions]
            : [];

    const isHoriButtonVisible =
        !horizontalHeight?.options.hasOwnProperty('visible') ||
        !!Number(horizontalHeight?.options?.visible);

    const isVertButtonVisible =
        !verticalWidth?.options?.hasOwnProperty('visible') ||
        !!Number(verticalWidth?.options?.visible);

    const layout: unknown =
        fieldset?.options?.layout || FIELDSET_LAYOUT.MID_RAILS;

    return (
        <>
            {!isQFP ? (
                <FormGroup>
                    <Form.Label className="fieldset-title">
                        {fieldset.title}
                    </Form.Label>
                </FormGroup>
            ) : null}

            {horizontalRailFields.length > 0 ? (
                <GenericFields
                    key={horizontalRail.name}
                    fields={horizontalRailFields}
                    fieldsetName="hori_mid_rail_positions"
                    fieldsetTitle="Horizontal Rail/s"
                    fieldSetMoreInfo={horizontalMoreInfo}
                    fieldsetLayout={layout}
                    isQFP={isQFP}
                />
            ) : null}

            {verticalRailFields.length > 0 ? (
                <GenericFields
                    key={verticalRail.name}
                    fields={verticalRailFields}
                    fieldsetName="vert_mid_rail_positions"
                    fieldsetTitle="Vertical Rail/s"
                    fieldSetMoreInfo={verticalMoreInfo}
                    fieldsetLayout={layout}
                    isQFP={isQFP}
                />
            ) : null}

            <ButtonContainer
                $isQFP={isQFP}
                style={{
                    margin:
                        horizontalRailFields.length > 0 ||
                        verticalRailFields.length > 0
                            ? '15px 0'
                            : 0,
                }}>
                {isHoriButtonVisible ? (
                    <CBCButton
                        tabIndex={-1}
                        className="button-blue"
                        onClick={handleButtonClick(
                            setFieldValue,
                            horiAmount,
                            values[horiAmount as keyof MidRailsValues]
                        )}
                        disabled={
                            values[horiAmount as keyof MidRailsValues] >=
                            horizontalAmount?.options?.maximum
                        }
                        iconName="Button-Add.svg">
                        Add Horizontal Rail
                    </CBCButton>
                ) : null}

                {isVertButtonVisible ? (
                    <CBCButton
                        tabIndex={-1}
                        onClick={handleButtonClick(
                            setFieldValue,
                            vertAmount,
                            values[vertAmount as keyof MidRailsValues]
                        )}
                        disabled={
                            values[vertAmount as keyof MidRailsValues] >=
                            verticalAmount?.options?.maximum
                        }
                        iconName="Button-Add.svg">
                        Add Vertical Rail
                    </CBCButton>
                ) : null}
            </ButtonContainer>
        </>
    );
};
