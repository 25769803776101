import {Product as ProductInterface} from 'components/customer/Product/entity/Product';
import {PartialRoom} from 'shared/types/PartialRoom';
import {
    BorderWidthTop,
    BorderWidthBottom,
    BorderWidthLeft,
    BorderWidthRight,
} from 'assets';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';

export const getBorderSelection = ({
    product,
    room,
    cabinetFormFieldDefaults,
    measurement,
}: {
    product: ProductInterface;
    room: PartialRoom;
    cabinetFormFieldDefaults: Record<string, string | number>;
    measurement: string;
}): SelectionInterface[] => {
    const rules = [];

    if (
        !product.hasOwnProperty('door_hang') &&
        typeof product.drawer_border_width_top != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults.drawer_border_width_top !=
                'undefined' &&
                cabinetFormFieldDefaults.drawer_border_width_top !=
                    product.drawer_border_width_top &&
                product.drawer_border_width_top != room.doorBorderWidthTop) ||
            (typeof cabinetFormFieldDefaults.drawer_border_width_top ==
                'undefined' &&
                product.drawer_border_width_top != room.doorBorderWidthTop)
        ) {
            rules.push({
                key: 'door_border_width_top',
                name: 'Border Width Top',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBorderWidthTopSet,
                set_key: 'isBorderWidthTopSet',
                room_data: `${room.doorBorderWidthTop} ${measurement}`,
                product_data: `${product.drawer_border_width_top} ${measurement}`,
                value: product.drawer_border_width_top,
                image: String(BorderWidthTop),
                apply_to_room: true,
            });
        }
    }

    if (
        product.hasOwnProperty('door_hang') &&
        typeof product.border_width_top != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults.border_width_top != 'undefined' &&
                cabinetFormFieldDefaults.border_width_top !=
                    product.border_width_top &&
                product.border_width_top != room.doorBorderWidthTop) ||
            (typeof cabinetFormFieldDefaults.border_width_top == 'undefined' &&
                product.border_width_top != room.doorBorderWidthTop)
        ) {
            rules.push({
                key: 'door_border_width_top',
                name: 'Border Width Top',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBorderWidthTopSet,
                set_key: 'isBorderWidthTopSet',
                room_data: `${room.doorBorderWidthTop} ${measurement}`,
                product_data: `${product.border_width_top} ${measurement}`,
                value: product.border_width_top,
                image: String(BorderWidthTop),
                apply_to_room: true,
            });
        }
    }

    if (
        !product.hasOwnProperty('door_hang') &&
        typeof product.drawer_border_width_bottom != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults.drawer_border_width_bottom !=
                'undefined' &&
                cabinetFormFieldDefaults.drawer_border_width_bottom !=
                    product.drawer_border_width_bottom &&
                product.drawer_border_width_bottom !=
                    room.doorBorderWidthBottom &&
                product.drawer_border_width_bottom !=
                    room.doorBorderWidthBottom) ||
            (typeof cabinetFormFieldDefaults.drawer_border_width_bottom ==
                'undefined' &&
                product.drawer_border_width_bottom !=
                    room.doorBorderWidthBottom)
        ) {
            rules.push({
                key: 'border_width_bottom',
                name: 'Border Width Bottom',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBorderWidthBottomSet,
                set_key: 'isBorderWidthBottomSet',
                room_data: `${room.doorBorderWidthBottom} ${measurement}`,
                product_data: `${product.drawer_border_width_bottom} ${measurement}`,
                value: product.drawer_border_width_bottom,
                image: String(BorderWidthBottom),
                apply_to_room: true,
            });
        }
    }

    if (
        product.hasOwnProperty('door_hang') &&
        typeof product.border_width_bottom != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults.border_width_bottom !=
                'undefined' &&
                cabinetFormFieldDefaults.border_width_bottom !=
                    product.border_width_bottom &&
                product.border_width_bottom != room.doorBorderWidthBottom) ||
            (typeof cabinetFormFieldDefaults.border_width_bottom ==
                'undefined' &&
                product.border_width_bottom != room.doorBorderWidthBottom)
        ) {
            rules.push({
                key: 'door_border_width_bottom',
                name: 'Border Width Bottom',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBorderWidthBottomSet,
                set_key: 'isBorderWidthBottomSet',
                room_data: `${room.doorBorderWidthBottom} ${measurement}`,
                product_data: `${product.border_width_bottom} ${measurement}`,
                value: product.border_width_bottom,
                image: String(BorderWidthBottom),
                apply_to_room: true,
            });
        }
    }

    if (
        !product.hasOwnProperty('door_hang') &&
        typeof product.drawer_border_width_left != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.drawer_border_width_left !=
                    'undefined' &&
                cabinetFormFieldDefaults.drawer_border_width_left !=
                    product.drawer_border_width_left &&
                product.drawer_border_width_left != room.doorBorderWidthLeft) ||
            (typeof cabinetFormFieldDefaults.drawer_border_width_left ==
                'undefined' &&
                product.drawer_border_width_left != room.doorBorderWidthLeft)
        ) {
            rules.push({
                key: 'door_border_width_left',
                name: 'Border Width Left',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBorderWidthLeftSet,
                set_key: 'isBorderWidthLeftSet',
                room_data: `${room.doorBorderWidthLeft} ${measurement}`,
                product_data: `${product.drawer_border_width_left} ${measurement}`,
                value: product.drawer_border_width_left,
                image: String(BorderWidthLeft),
                apply_to_room: true,
            });
        }
    }

    if (
        product.hasOwnProperty('door_hang') &&
        typeof product.border_width_left != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.door_border_width_left !=
                    'undefined' &&
                cabinetFormFieldDefaults.door_border_width_left !=
                    product.border_width_left &&
                product.border_width_left != room.doorBorderWidthLeft) ||
            (typeof cabinetFormFieldDefaults.door_border_width_left ==
                'undefined' &&
                product.border_width_left != room.doorBorderWidthLeft)
        ) {
            rules.push({
                key: 'door_border_width_left',
                name: 'Border Width Left',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBorderWidthLeftSet,
                set_key: 'isBorderWidthLeftSet',
                room_data: `${room.doorBorderWidthLeft} ${measurement}`,
                product_data: `${product.border_width_left} ${measurement}`,
                value: product.border_width_left,
                image: String(BorderWidthLeft),
                apply_to_room: true,
            });
        }
    }

    if (
        !product.hasOwnProperty('door_hang') &&
        typeof product.drawer_border_width_right != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.drawer_border_width_right !=
                    'undefined' &&
                cabinetFormFieldDefaults.drawer_border_width_right !=
                    product.drawer_border_width_right &&
                product.drawer_border_width_right !=
                    room.doorBorderWidthRight) ||
            (typeof cabinetFormFieldDefaults.drawer_border_width_right ==
                'undefined' &&
                product.drawer_border_width_right != room.doorBorderWidthRight)
        ) {
            rules.push({
                key: 'door_border_width_right',
                name: 'Border Width Right',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBorderWidthRightSet,
                set_key: 'isBorderWidthRightSet',
                room_data: `${room.doorBorderWidthRight} ${measurement}`,
                product_data: `${product.drawer_border_width_right} ${measurement}`,
                value: product.drawer_border_width_right,
                image: String(BorderWidthRight),
                apply_to_room: true,
            });
        }
    }

    if (
        product.hasOwnProperty('door_hang') &&
        typeof product.border_width_right != 'undefined'
    ) {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.door_border_width_right !=
                    'undefined' &&
                cabinetFormFieldDefaults.door_border_width_right !=
                    product.border_width_right &&
                product.border_width_right != room.doorBorderWidthRight) ||
            (typeof cabinetFormFieldDefaults.door_border_width_right ==
                'undefined' &&
                product.border_width_right != room.doorBorderWidthRight)
        ) {
            rules.push({
                key: 'door_border_width_right',
                name: 'Border Width Right',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isBorderWidthRightSet,
                set_key: 'isBorderWidthRightSet',
                room_data: `${room.doorBorderWidthRight} ${measurement}`,
                product_data: `${product.border_width_right} ${measurement}`,
                value: product.border_width_right,
                image: String(BorderWidthRight),
                apply_to_room: true,
            });
        }
    }

    return rules;
};
