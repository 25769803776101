import {Product as ProductInterface} from 'components/customer/Product/entity/Product';
import {PartialRoom} from 'shared/types/PartialRoom';
import {RailHeight, RailWidth} from 'assets';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';

export const getRailSelection = ({
    product,
    room,
    cabinetFormFieldDefaults,
    measurement,
}: {
    product: ProductInterface;
    room: PartialRoom;
    cabinetFormFieldDefaults: Record<string, string | number>;
    measurement: string;
}): SelectionInterface[] => {
    const rules = [];

    if (typeof product.hori_height != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.hori_height != 'undefined' &&
                cabinetFormFieldDefaults.hori_height != product.hori_height) ||
            (typeof cabinetFormFieldDefaults.hori_height == 'undefined' &&
                product.hori_height != room.doorAdvancedRailHeight)
        ) {
            rules.push({
                key: 'door_advanced_rail_height',
                name: 'Rail Height',
                is_saved:
                    room.customerOptions &&
                    room.customerOptions.isRailHeightSet,
                set_key: 'isRailHeightSet',
                room_data: `${room.doorAdvancedRailHeight} ${measurement}`,
                product_data: `${product.hori_height} ${measurement}`,
                value: product.hori_height,
                image: String(RailHeight),
                apply_to_room: true,
            });
        }
    }

    if (typeof product.vert_width != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.vert_width != 'undefined' &&
                cabinetFormFieldDefaults.vert_width != product.vert_width) ||
            (typeof cabinetFormFieldDefaults.vert_width == 'undefined' &&
                product.vert_width != room.doorAdvancedRailWidth)
        ) {
            rules.push({
                key: 'door_advanced_rail_width',
                name: 'Rail Width',
                is_saved:
                    room.customerOptions && room.customerOptions.isRailWidthSet,
                set_key: 'isRailWidthSet',
                room_data: `${room.doorAdvancedRailWidth} ${measurement}`,
                product_data: `${product.vert_width} ${measurement}`,
                value: product.vert_width,
                image: String(RailWidth),
                apply_to_room: true,
            });
        }
    }

    if (typeof product.bar_width != 'undefined') {
        if (
            (typeof cabinetFormFieldDefaults != 'undefined' &&
                typeof cabinetFormFieldDefaults.bar_width != 'undefined' &&
                cabinetFormFieldDefaults.bar_width != product.bar_width &&
                product.bar_width != room.doorAdvancedBarWidth) ||
            (typeof cabinetFormFieldDefaults.bar_width == 'undefined' &&
                product.bar_width != room.doorAdvancedBarWidth)
        ) {
            rules.push({
                key: 'door_advanced_rail_width',
                name: 'Rail Width',
                is_saved:
                    room.customerOptions && room.customerOptions.isBarWidthSet,
                set_key: 'isBarWidthSet',
                room_data: `${room.doorAdvancedBarWidth} ${measurement}`,
                product_data: `${product.bar_width} ${measurement}`,
                value: product.bar_width,
                image: String(RailWidth),
                apply_to_room: true,
            });
        }
    }

    return rules;
};
