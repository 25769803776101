import {Product as ProductInterface} from 'components/customer/Product/entity/Product';
import {PartialRoom} from 'shared/types/PartialRoom';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';
import {ShelfSetBack} from 'assets';

export const getShelfSelection = ({
    product,
    room,
    measurement,
}: {
    product: ProductInterface;
    room: PartialRoom;
    measurement: string;
}): SelectionInterface[] => {
    const rules = [];

    const changedShelf =
        product.shelf_offset &&
        product.shelf_offset.find((item) => item != room.shelfSetBack);
    if (changedShelf) {
        rules.push({
            key: 'shelf_set_back',
            name: 'Shelf Set Back',
            is_saved:
                room.customerOptions && room.customerOptions.isShelfSetBackSet,
            set_key: 'isShelfSetBackSet',
            room_data: `${room.shelfSetBack} ${measurement}`,
            product_data: `${changedShelf} ${measurement}`,
            value: changedShelf,
            image: String(ShelfSetBack),
            apply_to_room: true,
        });
    }

    return rules;
};
