import {GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';
import {useComponentDrawer} from 'hooks';
import React, {useCallback, useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import {cloneDeep} from 'lodash';
import {Field} from 'components/customer/Product/entity/Field';
import {Fieldset} from 'components/customer/Product/entity/Fieldset';

interface DrawerFaceInterface {
    index: number;
    fieldName: string;
    isQFP: boolean;
    values: {[key: string]: any};
    faceHeight: Fieldset;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => Promise<any>;
    selectHandler: (field: string, value: any) => void;
    updateManuallyUpdatedFace: (index: number) => void;
}

interface DrawerFacesInterface {
    fields: Field[];
    fieldset: Fieldset;
    isQFP?: boolean;
    selectHandler?: (field: string, value: any) => void;
}

const DrawerFace = ({
    index,
    fieldName,
    isQFP,
    values,
    faceHeight,
    setFieldValue,
    selectHandler,
    updateManuallyUpdatedFace,
}: DrawerFaceInterface) => {
    const handleFaceUpdate = useCallback(
        (name: string, value: number) => {
            const faceHeights = cloneDeep(values[String(fieldName)]);

            updateManuallyUpdatedFace(index);
            faceHeights[Number(index)] = value;

            if (selectHandler) selectHandler(fieldName, faceHeights);
            else setFieldValue(fieldName, faceHeights);
        },
        [values, fieldName, selectHandler]
    );

    const indexedValues = useMemo(() => {
        return values[String(fieldName)]
            ? {
                  [fieldName]: values[String(fieldName)][Number(index)],
              }
            : {};
    }, [values, fieldName, index]);

    return (
        <GenericFields
            fieldSetIndex={index}
            fields={faceHeight.fields}
            fieldsetName={faceHeight.name}
            fieldsetTitle={isQFP ? false : faceHeight.title}
            hasFullWidth={isQFP}
            indexedValues={indexedValues}
            isQFP={isQFP}
            selectHandler={handleFaceUpdate}
        />
    );
};

export const DrawerFaces = ({
    fields,
    fieldset,
    isQFP = false,
    selectHandler,
}: DrawerFacesInterface) => {
    const {values, setFieldValue} = useFormikContext<{[key: string]: any}>();
    const {fieldName, fieldsets, updateManuallyUpdatedFace} =
        useComponentDrawer(fields, fieldset, selectHandler) as {
            fieldName: string;
            fieldsets: Fieldset[];
            updateManuallyUpdatedFace: (index: number) => void;
        };

    const drawerFacesContent = useMemo(() => {
        return (
            <Row>
                <Col>
                    {fieldsets.map((faceHeight, index) => {
                        return (
                            <DrawerFace
                                index={index}
                                faceHeight={faceHeight}
                                fieldName={fieldName}
                                values={values}
                                setFieldValue={setFieldValue}
                                updateManuallyUpdatedFace={
                                    updateManuallyUpdatedFace
                                }
                                selectHandler={selectHandler}
                                isQFP={isQFP}
                                key={index}
                            />
                        );
                    })}
                </Col>
            </Row>
        );
    }, [fieldsets, values[String(fieldName)]]);

    return drawerFacesContent;
};
