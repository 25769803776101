import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Field} from 'shared';
import {useFormikContext} from 'formik';
import {useProductContext} from 'contexts';
import excel from 'shared/Excel';

export const Sizes = ({fields, hasPreview = false}) => {
    const {values, setFieldValue} = useFormikContext();
    const {getMaterialOptions} = useProductContext();

    const hiddenFields = [];
    const hiddenFieldModifier = fields
        .filter(
            (field) =>
                typeof field.type === 'string' &&
                field.type.indexOf('hidden') > -1
        )
        .map((field) => {
            hiddenFields.push(field.name);
            const materialOptions = getMaterialOptions();

            try {
                return excel.calculate(field.value, {
                    ...values,
                    ...materialOptions,
                });
            } catch (err) {
                return 0;
            }
        });

    useEffect(() => {
        if (hiddenFields.length) {
            hiddenFields.forEach((field, i) => {
                setFieldValue(field, parseFloat(hiddenFieldModifier[i]));
            });
        }
    }, hiddenFieldModifier);

    return (
        <Row>
            <Col>
                {fields && Array.isArray(fields)
                    ? fields.map((field, i) => {
                          const fieldsetSpan = 12;
                          const formControlSpan = 4;

                          if (
                              typeof field.type === 'string' &&
                              field.type.indexOf('hidden') > -1
                          ) {
                              return <React.Fragment key={i}></React.Fragment>;
                          }

                          return (
                              <Field
                                  key={i}
                                  field={field}
                                  fieldSpan={fieldsetSpan}
                                  formControlSpan={formControlSpan}
                              />
                          );
                      })
                    : null}
            </Col>
        </Row>
    );
};
