import {GenericObject} from 'components/customer/Product/entity';
import {ProductDefault} from 'components/customer/Product/entity/ProductDefault';
import {Drawer} from 'components/customer/Product/entity/Drawer';

export interface InitialValues extends GenericObject {
    glassSubPanels: boolean[][];
    drawers: Drawer[];
}

export const mapDefaults = (
    initialValues: InitialValues,
    defaultValues: ProductDefault
) => {
    if (
        defaultValues &&
        defaultValues.hasOwnProperty('cabinet_form_field_defaults') &&
        defaultValues.hasOwnProperty('cabinet_form_fields')
    ) {
        const defualtOverrides = Object.keys(
            defaultValues.cabinet_form_fields
        ).filter((key) => {
            return [1, 2].includes(
                defaultValues.cabinet_form_fields[String(key)]
            );
        });

        initialValues.glassSubPanels = [
            [false, false, false],
            [false, false, false],
            [false, false, false],
        ];

        Object.keys(defaultValues.cabinet_form_field_defaults).forEach(
            (formField) => {
                if (defualtOverrides.includes(formField)) {
                    if (formField.indexOf('sub_panel') >= 0) {
                        const subPanelIndex = parseInt(
                            formField.match(/\d+/g)[0]
                        );
                        const row = Math.floor((subPanelIndex - 1) / 3);
                        const col = (subPanelIndex - 1) % 3;

                        initialValues.glassSubPanels[Number(row)][Number(col)] =
                            defaultValues.cabinet_form_field_defaults[
                                String(formField)
                            ] == '1';
                        return;
                    }

                    if (
                        formField == 'hori_amount' ||
                        formField == 'vert_amount'
                    ) {
                        initialValues[String(formField)] = parseInt(
                            defaultValues.cabinet_form_field_defaults[
                                String(formField)
                            ]
                        );
                        return;
                    }
                    let value: string | number | boolean =
                        defaultValues.cabinet_form_field_defaults[
                            String(formField)
                        ];

                    // Apparently value == 2 is value == 1
                    if (value == '2') value = 1;

                    if (
                        [
                            'double_sided_carc',
                            'double_sided_ext',
                            'hor_grain_carc',
                            'hor_grain_ext',
                        ].includes(formField)
                    ) {
                        value = value == 1;
                    }

                    initialValues[String(formField)] = value;
                }
            }
        );
    }

    return initialValues;
};
